import {inject, Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {AuthState} from './auth.state';
import {AuthRoles} from './auth.roles';
import {Address} from '../modules/admin-features/services/agency.service';
import {BoomerHttpClient} from '../shared/services/boomer-http-client';
import {applyRuleToApplicationUser, parseRule, RoleFlagRule} from './roleRuleEngine';
import {ApplicationUserService, ProfileAccessLevel} from '../shared/services/application-user.service';

export interface ConfirmAccountCreationData {
  token: string;
  password: string;
  termsOfServiceAccepted: boolean;
  privacyPolicyAccepted: boolean;
}

export interface ApplicantInvitationDetails {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  birthDate: string;
  referringPersonName: string;
  employer: string;
  email: string;
}

export type ProfileShare = {
  email: string,
  accessLevel: ProfileAccessLevel,
  accountExists: boolean
};

export interface ApplicantProfile {
  uuid: string;
  address: Address;
  completedSteps: {
    drugs: boolean;
  };
  birthDate?: string;
  city?: string;
  countyName: string;
  primaryEmail: string;
  firstName: string;
  lastName: string;
  middleName?: string;
  primaryPhoneNumber?: string;
  secondaryEmail?: string;
  secondaryPhoneNumber?: string;
  state?: string;
  zipCode?: string;
  interestedInMailOrder?: boolean;
  servicingAgentUuid?: string;
  servicingAgent?: {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    picture: string;
    uuid: string;
    email: string;
    stateLicense?: string;
    stateLicenseForState?: string;
  };
  shares: Array<ProfileShare>;
}

@Injectable()
export class AuthService {
  private readonly httpClient = inject(BoomerHttpClient);
  private readonly authState = inject(AuthState);
  private readonly applicationUserService = inject(ApplicationUserService);
  public isAdmin$ = this.hasRole(AuthRoles.ROLE_BOOMER_ADMIN);
  public isBoomerUser$ = this.hasRole(AuthRoles.ANY_BOOMER_USER);

  hasRole(role: RoleFlagRule): Observable<boolean> {
    return this.applicationUserService.currentUserProfile$
      .pipe(
        map(profile => applyRuleToApplicationUser(parseRule(role), profile))
      );
  }

  activate(email: string, password: string): Observable<any> {
    return this.httpClient
      .post(`/applicant-user/activate`, {email, password});
  }

  createUserFromInvitation(userData) {
    return this.httpClient.post(
      `/referring-company-user`,
      userData,
    );
  }

  createApplicantFromInvitation(applicantData) {
    return this.httpClient.post(
      `/applicant-user/invitation`,
      applicantData,
    );
  }

  createBoomerUserFromInvitation(data) {
    return this.httpClient.post(
      `/boomer-user/invitation`,
      data
    );
  }

  getBoomerUserInvitationDetails(invitationToken: string) {
    return this.httpClient.get(
      `/boomer-user-invitation/public/${invitationToken}`,
    );
  }

  getApplicantInvitationDetails(invitationToken: string) {
    return this.httpClient.get<ApplicantInvitationDetails>(
      `$/applicant-user-invitation/public/${invitationToken}`,
    );
  }

  getReferringCompanyName(referralToken: string) {
    return this.httpClient.get(
      `/referral-token/public/${referralToken}`,
    );
  }

  submitForgotPassword(userData) {
    return this.httpClient.post(`/password-reset`, userData);
  }

  submitResetPassword(userData) {
    return this.httpClient.patch(
      `/application-user/public/password`,
      userData,
    );
  }

  getUserProfile() {
    const token = this.authState.decodedToken;
    if (token) {
      return token;
    }
    return {user_uuid: '', user_name: ''};
  }

  getUserRoles(): Array<AuthRoles> {
    throw new Error('deprecated');
  }

  confirmAccountCreation(data: ConfirmAccountCreationData) {
    return this.httpClient.patch(`/application-user/public/email/upgrade-account-confirmation`, data);
  }

  startPasswordResetProcess() {
    return this.httpClient.post(
      `/application-user/change-password`,
      {
        redirectUrl: window.location.href
      },
      {
        responseType: 'text'
      }
    ).subscribe(url => {
      window.location.href = url;
    });
  }
}
