import {Moment} from 'moment';
import {MedicalPlansDetails} from './medical-plans-details/medical-plans-details.facade';

export const getDeductibleFor256 = (plan: MedicalPlansDetails, coverageStart: Moment): number[] | undefined => {
  const { planSubType } = plan;
  const planYear = coverageStart.year().toString();
  if (planSubType.includes('High Deductible')) {
    switch (planYear) {
      case '2024': return [2800];
      case '2023': return [2700];
      case '2022': return [2490];
      default: return [2340];
    }
  } else if (planSubType.includes('Plan F')) {
    return [0, 0];
  } else if (planSubType.includes('Plan C')) {
    switch (planYear) {
      case '2024': return [0, 240];
      case '2023': return [0, 226];
      default: return [0, 0];
    }
  } else if (planSubType.includes('Plan L')) {
    switch (planYear) {
      case '2024': return [408, 240];
      case '2023': return [363, 226];
      case '2020': return [352, 198];
      default: return [363, 203];
    }
  } else if (
    planSubType.includes('Plan K') ||
    planSubType.includes('Plan M')
  ) {
    switch (planYear) {
      case '2024': return [816, 240];
      case '2023': return [726, 226];
      case '2020': return [704, 198];
      default: return [726, 203];
    }
  } else if (planSubType.includes('Plan A')) {
    switch (planYear) {
      case '2024': return [1632, 240];
      case '2023': return [1556, 226];
      case '2022': return [1556, 203];
      default: return [1484, 203];
    }
  } else {
    switch (planYear) {
      case '2024': return [0, 240];
      case '2023': return [0, 226];
      case '2022': return [0, 203];
      case '2020': return [0, 198];
      default: return [0, 203];
    }
  }
};
