import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ColumnDef} from '../boomer-table.component';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Moment} from 'moment/moment';
import {MatMenu, MatMenuTrigger} from '@angular/material/menu';

export type FilterType = { __type: 'text', value: string }
  | { __type: 'date', from: Moment, to: Moment }
  | { __type: 'select', value: string }
  | { __type: 'multi-select', values: Array<string> }
  | { __type: 'agent', value: string }
  | { __type: 'boomer_user', value: string };

@Component({
  selector: 'app-filter-menu',
  templateUrl: './filter-menu.component.html',
  styleUrls: ['./filter-menu.component.scss']
})
export class FilterMenuComponent<T extends object> implements OnInit {

  @Input()
  column: ColumnDef<T>;

  @Output()
  filter = new EventEmitter<FilterType | null>();

  form: UntypedFormGroup;

  @ViewChild('menuTrigger')
  trigger: MatMenuTrigger;

  @ViewChild('menu')
  menu: MatMenu;

  get hasValue() {
    switch (this.column.type) {
      case 'exact_datetime':
      case 'date':
        return this.form.value.from !== null && this.form.value.to !== null;
      case 'text':
      case 'agent':
      case 'boomer_user':
      case 'select':
        return this.form.value.value !== null;
      case 'multi-select':
        return Object.values(this.form.value).some((v) => v === true);
    }
  }

  constructor(private formBuilder: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    if (!this.column.isFilterable) {
      throw new Error(`Column(${this.column.name}) is not filterable`);
    }

    switch (this.column.type) {
      case 'select':
      case 'agent':
      case 'boomer_user':
      case 'text':
        this.form = this.formBuilder.group({
          value: null
        });
        break;
      case 'multi-select':
        this.form = this.formBuilder.group(this.column.options.reduce((acc, curr) => {
          acc[curr.value] = false;
          return acc;
        }, {}));
        break;
      case 'date':
      case 'exact_datetime':
        this.form = this.formBuilder.group({
          from: null,
          to: null
        });
        break;
    }
  }

  onMenuOpen() {
    // We don't want to focus the first radio button
    if (this.column.type === 'select') {
      return;
    }

    const input = document.querySelector<HTMLInputElement>(`#${this.menu.panelId} input`);
    if (!input) {
      return;
    }
    input.focus();
  }

  onSubmit() {
    if (!this.column.isFilterable) {
      throw new Error(`Column(${this.column.name}) is not filterable`);
    }

    this.trigger.closeMenu();

    switch (this.column.type) {
      case 'text': {
        const value = this.form.value.value;
        this.filter.emit({__type: 'text', value});
        break;
      }
      case 'exact_datetime':
      case 'date': {
        const from = this.form.value.from;
        const to = this.form.value.to;
        this.filter.emit({__type: 'date', from, to});
        break;
      }
      case 'select': {
        const value = this.form.value.value;
        this.filter.emit({__type: 'select', value});
        break;
      }
      case 'multi-select': {
        const values = Object.entries(this.form.value)
          .filter(([_, entryValue]) => entryValue === true)
          .map(([entryName]) => entryName);
        this.filter.emit({__type: 'multi-select', values});
        break;
      }
      case 'boomer_user':
      case 'agent': {
        const value = this.form.value.value;
        this.filter.emit({__type: 'agent', value});
      }
    }
  }

  clear() {
    switch (this.column.type) {
      case 'date':
      case 'exact_datetime':
        this.form.patchValue({from: null, to: null}, {emitEvent: false});
        break;
      case 'text':
      case 'select':
      case 'agent':
      case 'boomer_user':
        this.form.patchValue({value: null}, {emitEvent: false});
        break;
    }

    this.trigger.closeMenu();
    this.filter.emit(null);
  }
}
