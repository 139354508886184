import {inject, Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {map, shareReplay, switchMap, tap} from 'rxjs/operators';
import {BoomerHttpClient} from '../../../shared/services/boomer-http-client';
import {AbstractApplicantProfileService} from './abstract-applicant-profile.service';

export interface DrugItem {
  chemicalName: string;
  connectureId: number;
  drugName: string;
  drugType: string;
  genericDrugID: number;
  genericDrugName: string;
  referenceNDC: string;
}

export interface UserDrugItem {
  connectureDrugId: number;
  selectedDosageConnectureId: string;
  selectedNDC: string;
  drugName: string;
  drugType: string;
  frequency: DrugFrequency;
  id: number;
  labelName: string;
  packageSize: number;
  packageSizeUnitOfMeasure: string;
  quantity: number;
}

export interface DrugOptions {
  connectureDosageId: string;
  packageReferenceNDC?: string;
  quantity?: number;
  frequency: DrugFrequency;
}

export enum DrugFrequency {
  EVERY_MONTH = 'EVERY_MONTH',
  EVERY_2_MONTHS = 'EVERY_2_MONTHS',
  EVERY_3_MONTHS = 'EVERY_3_MONTHS',
  EVERY_6_MONTHS = 'EVERY_6_MONTHS',
  EVERY_12_MONTHS = 'EVERY_12_MONTHS',
}

export interface DrugDetails {
  chemicalName: string;
  connectureDrugId: number;
  dosages: [
    {
      commonDaysOfSupply: number;
      commonMetricQuantity: number;
      commonUserQuantity: number;
      connectureDosageId: string;
      id: number;
      isCommonDosage: true;
      labelName: string;
      packages: [
        {
          commonMetricQuantity: number;
          commonUserQuantity: number;
          id: number;
          isCommonPackage: true;
          packageDescription: string;
          packageQuantity: number;
          packageSize: number;
          packageSizeUnitOfMeasure: string;
          referenceNDC: string;
          totalPackageQuantity: number;
        }
      ];
      referenceNDC: string;
    }
  ];
  drugName: string;
  drugType: string;
  drugTypeID: number;
  drugTypeNDA: string;
  id: string;
}

@Injectable()
export class DrugsService {
  private readonly http = inject(BoomerHttpClient);
  private readonly applicantProfileService = inject(AbstractApplicantProfileService);

  loadDrugs: BehaviorSubject<boolean> = new BehaviorSubject(false);
  userDrugs$: Observable<UserDrugItem[]> = this.loadDrugs.pipe(
    switchMap(() => this.getUserDrugs()),
    shareReplay(1)
  );

  searchDrug(query: string): Observable<DrugItem[]> {
    return this.http.get<DrugItem[]>(
      `/drug/search?query=${query}`
    );
  }

  getUserDrugs(): Observable<UserDrugItem[]> {
    return this.applicantProfileService.applicantId$
      .pipe(
        switchMap(applicantId =>
          this.http.get<UserDrugItem[]>(`/drug/applicant/${applicantId}`)
        ),
        map((res) => res.reverse()),
        tap((res) => console.log(res))
      );
  }

  getDrugDetails(drugId): Observable<DrugDetails> {
    return this.http.get<DrugDetails>(
      `/drug/search/details/${drugId}`
    );
  }

  addDrugToUser(connectureDrugId: string, drugOptions: DrugOptions) {
    return this.applicantProfileService.getApplicantId().pipe(
      switchMap(id =>
        this.http.post(
          `/drug/applicant/${id}`,
          {
            connectureDrugId,
            connectureDosageId: drugOptions.connectureDosageId,
            packageReferenceNDC: drugOptions.packageReferenceNDC,
            quantity: drugOptions.quantity,
            frequency: drugOptions.frequency,
          })
          .pipe(
            tap(() => this.loadDrugs.next(true)),
          )
      )
    );
  }

  editDrug(
    drugId,
    connectureDrugId,
    connectureDosageId,
    packageReferenceNDC,
    quantity,
    frequency
  ) {
    return this.applicantProfileService.getApplicantId().pipe(
      switchMap(id => this.http.put(
          `/drug/${drugId}/applicant/${id}`,
          {
            connectureDrugId,
            connectureDosageId,
            packageReferenceNDC,
            quantity,
            frequency,
          }
        )
      ),
      tap(() => this.loadDrugs.next(true))
    );
  }

  deleteDrugFromUser(drugId: string) {
    return this.applicantProfileService.getApplicantId().pipe(
      switchMap(id =>
        this.http
          .delete(`/drug/${drugId}/applicant/${id}`)
          .pipe(
            tap(() => this.loadDrugs.next(true)),
          )
      )
    );
  }
}
